<template>
  <div id="suppliers-list-list-view" class="suppliers-list-container">

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Suppliers'">
      <template v-slot:right-end>
        <div class="vx-row">
          <vs-button class="alpha-clear" v-if="selectedAlpha" color="warning" type="flat" icon-pack="feather" icon="icon-x" size="small" @click="clearSelectedAlpha()"></vs-button>
          <vs-pagination-modified v-model="selectedAlphaCurrentX" :total="26" :max="26" v-on:change="pageChanged"></vs-pagination-modified>
          <div class="p-3"></div>
          <vs-button @click="openAddSupplierPage" type="filled" icon-pack="feather"
                     icon="icon-plus">{{ $t('AddSupplier') }}
          </vs-button>
        </div>
      </template>
    </breadcrumb-base>

  <!-- Error List State-->
  <transition name="fade">
    <div class="h-screen flex w-full bg-img" v-if="errorFetching">
      <div
        class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
        <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
             class="mx-auto mb-4 max-w-full">
        <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('ErrorData')}}</h1>
      </div>
    </div>
  </transition>

  <transition name="fade">
    <div class="vx-row">
      <div class="vx-col md:w-1/5 mt-20">
        <vx-card>
          <div class="vx-col m-3" v-for="(item, index) in suppliersFilters">
            <vs-row class="vs-row">
              <h4>{{item.value | title}}</h4></vs-row>
            <ul class="centerx mt-4">
              <li v-for="(option, index) in item.options">
                <vs-radio v-model="filterAccountStatus[item.value]" v-on:change="filterChanged" :vs-value="option">{{option}}</vs-radio>
              </li>
            </ul>
            <vs-divider v-if="suppliersFilters.length > 0 && index !== suppliersFilters.length-1"/>
          </div>
          <vs-button v-if="filterAccountStatus" class="mt-3 w-full py-4 px-3" color="primary" @click="clearAllFilter()">{{$t('ClearAllFilters').toUpperCase()}}</vs-button>
        </vx-card>
      </div>
      <div class="vx-col md:w-4/5">
        <!-- Empty List State -->
        <vs-row class="">
          <vs-spacer></vs-spacer>

          <div
            class="con-input-search vs-table--search">
            <vs-input
              class="input-search"
              @input="searchDebounce"
              v-model="searchText"
              type="text"
              icon-no-border icon="search" />
          </div>
        </vs-row>

        <transition name="fade">
          <div class="py-6 flex w-full bg-img" v-if="suppliers.length === 0 && !errorFetching">
            <div
              class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--              <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                   class="mx-auto mb-4 max-w-full">-->
              <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('NoData')}}</h1>
            </div>
          </div>
        </transition>
        <vs-table-modified v-show="suppliers.length > 0 && !errorFetching" ref="table"
                  :max-items="itemsPerPage"
                  :data="suppliers">

          <template slot="thead">
            <vs-th >{{$t('SupplierName')}}</vs-th>
            <vs-th >{{$t('SupplierCompany')}}</vs-th>
            <vs-th >{{$t('Mobile')}}</vs-th>
            <vs-th >{{$t('AccountStatus')}}</vs-th>
            <vs-th>{{ $t('Action') }}</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

              <vs-td>
                <p class="supplier-name">{{ tr.full_name | title }}</p>
              </vs-td>
              <vs-td>
                <p class="supplier-name font-medium truncate">{{ tr.company | capitalize }}</p>
              </vs-td>

              <vs-td>
                <p class="supplier-mobile">{{ tr.mobile }}</p>
              </vs-td>

              <vs-td>
                <vs-chip :color="getStatusColor(tr.status)" class="supplier-status">{{
                  tr.status | title }}
                </vs-chip>
              </vs-td>


              <vs-td class="whitespace-no-wrap vx-row">
                <vs-button class="" type="border" @click="openSupplierEditPage(tr.id)">{{ $t('Edit') }}</vs-button>
                <!--              <div class="px-2"></div>-->
                <vs-button class="ml-2" type="border" :color="getIsActive(tr.status) ? 'danger' : 'success'" @click="inActivateSupplier(tr.id, getIsActive(tr.status) ? 'inactive' : 'active')">{{getIsActive(tr.status) ? $t('Deactivate') : $t('Activate') }}</vs-button>
              </vs-td>

            </vs-tr>
            </tbody>
          </template>

          <template slot="footer">
            <div class="vx-row flex">
<!--              <vs-button @click="loadMoreSuppliers" type="border" v-if="(vPage+1) < vTotalPages">Load-->
<!--                More-->
<!--              </vs-button>-->
            </div>
          </template>
          <template slot="footer-left">
            <div class="flex">
              <vs-new-pagination :total="vTotalPages" v-model="currentPage" v-on:change="listPageChanged">
              </vs-new-pagination>
            </div>
          </template>
        </vs-table-modified>
      </div>
    </div>
  </transition>
  </div>
</template>

<script>

export default {
  name: 'ViewSuppliers',
  data() {
    return {
      itemsPerPage: 20,
      isMounted: false,
      errorFetching: false,
      filterAccountStatus: {},
      selectedAlpha: null,
      selectedAlphaCurrentX: 1,
      alpha: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Suppliers', i18n: 'Suppliers', active: true },
      ],
      currentPage: 1,
      searchText: '',
    }
  },
  computed: {
    suppliers() {
      return this.$store.state.suppliers.suppliers
    },
    vPage() {
      return this.$store.state.suppliers.vPage + 1
    },
    limit() {
      return this.$store.state.suppliers.limit
    },
    vTotalPages() {
      return this.$store.state.suppliers.vTotalPages
    },
    suppliersFilters() {
      return this.$store.state.suppliers.suppliersFilters
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.suppliers.length
    },
  },
  methods: {
    inActivateSupplier(supplierId, activate) {
      this.$vs.loading()
      this.$store.dispatch('suppliers/deactivateSupplier', { supplierId, status: activate })
        .then((data) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          this.loadSuppliers(null, this.getFilters(), this.selectedAlpha)
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    openSupplierEditPage(item) {
      this.$router.push({ name: 'supplier-edit', params: { supplierId: item } })
    },
    clearSelectedAlpha() {
      this.selectedAlpha = null
      this.selectedAlphaCurrentX = 1

      this.loadSuppliers(0, this.getFilters(), this.selectedAlpha)
    },
    clearFilter(key) {
      this.filterAccountStatus[key] = null
      this.filterChanged()
    },
    clearAllFilter() {
      this.filterAccountStatus = {}
      this.filterChanged()
    },
    pageChanged(newIndex) {
      if (isNaN(newIndex)) {
        this.selectedAlpha = newIndex
        this.selectedAlphaCurrentX = newIndex
        this.loadSuppliers(0, this.getFilters(), newIndex)
      }
    },

    searchDebounce() {
      clearTimeout(this.interval)
      this.interval = setTimeout(() => {
        this.loadSuppliers('0', this.getFilters(), this.searchText)
      }, 500)
    },
    listPageChanged(item) {
      this.loadSuppliers(this.currentPage - 1, this.getFilters(), this.searchText)
    },
    getFilters() {
      const filter = {}
      Object.keys(this.filterAccountStatus).forEach((key) => {
        if (this.filterAccountStatus[key]) {
          filter[key] = this.filterAccountStatus[key]
        }
      })
      return filter
    },
    filterChanged() {
      this.loadSuppliers(false, this.getFilters(), this.selectedAlpha)
    },
    openAddSupplierPage() {
      this.$router.push({ name: 'supplier-add' })
    },
    getIsActive(status) {
      return status.toLowerCase() == 'active'
    },
    getStatusColor(status) {
      if (status.toLowerCase() == 'active') return 'success'
      return 'warning'
    },
    loadSuppliers(page = '0', filter = null, search = null) {
      if (page == 0) this.currentPage = 1
      filter = this.getFilters()
      search = search != null ? search : this.selectedAlpha
      this.$vs.loading()
      return this.$store.dispatch('suppliers/fetchSuppliers', { page, filter, search })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((err) => {
          this.$vs.loading.close()
          this.errorFetching = true
          console.log(err)
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: err.response.data.message || err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    // this.loadSuppliers()
  },
  mounted() {
    this.isMounted = true
  },
}
</script>

<style lang="scss">
  #suppliers-list-list-view {
    .supplier-image {
      /*max-width: 200px;*/
      max-height: 40px;
      object-fit: cover;
    }

    .vs-con-table {

      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .suppliers-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .supplier-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

          td {
            padding: 20px;

            &:first-child {
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }

            &:last-child {
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }

          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
</style>
